import React from 'react';

export const AnswersHeader = () => (
	<>
		<header className="bg-grey-200">
			<h1 className="leading-tight text-3xl-f">
				Great, find your recommendations below and how we can help
			</h1>
		</header>
	</>
);
