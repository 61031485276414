import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Head } from '../components/templates/head';

import { Answers } from '../components/organisms/answers';

// markup
const AnswersPage = ({ location }) => {
	const answers = location?.state?.answers || [];
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	return (
		<>
			<Head title={site.siteMetadata.title} />
			<main id="main">
				<div className={clsx('md:grid', 'md:min-h-screen')}>
					<div
						className="w-full max-w-full min-w-0 flow-vertical"
						style={{ '--space-vertical': 'var(--space-m-l)' }}
					>
						<Answers answers={answers} />
					</div>
				</div>
			</main>
		</>
	);
};

AnswersPage.defaultProps = {
	location: undefined,
};

AnswersPage.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	location: PropTypes.object,
};

export default AnswersPage;
