import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { answersType } from '../../../types';

import { getIcon } from '../../../utils/score-icon';

import { Accordion } from '../accordion/index';

import * as answersAdviceStyles from './index.module.css';

export const AnswersAdvice = ({ answers, hasMargin }) => {
	const answersWithScore = answers
		.filter(
			(answer) => typeof answer.score !== 'undefined' && answer.resultText
		)
		.sort((a, b) => b.score - a.score);
	return (
		<section
			className={clsx(
				'bg-white',
				answersAdviceStyles.fullWidth,
				hasMargin ? undefined : '!mt-0'
			)}
		>
			<div className="px-4 py-10">
				<div className="mx-auto wrapper-small">
					<h2 className="mb-[30px] text-xl font-centra-medium">
						Advice based on answers
					</h2>
					<ul className="border-b border-b-blue-200">
						{answersWithScore.length > 0
							? answersWithScore.map((answer, index) => (
									<li key={answer.id}>
										<Accordion
											title={answer.category}
											icon={getIcon(answer.score)}
											buttonId={`accordButton${answer.id}`}
											contentId={`accordContent${answer.id}`}
											headingLevel={3}
											position={index + 1}
											score={answer.score}
											className={clsx(
												answer.score >= 1
													? 'text-alert-400'
													: ''
											)}
										>
											<p>{answer.resultText}</p>
										</Accordion>
									</li>
							  ))
							: null}
					</ul>
				</div>
			</div>
		</section>
	);
};

AnswersAdvice.defaultProps = {
	hasMargin: false,
};

AnswersAdvice.propTypes = {
	answers: answersType.isRequired,
	hasMargin: PropTypes.bool,
};
