import React from 'react';
import { clsx } from 'clsx';

import { answersType } from '../../../types';

import { getAnswersScore, checkNoComms } from '../../../utils';

import { Header } from '../../molecules/header';
import { NextStepComponent } from '../../molecules/next-steps';
import { CloseButton } from '../../atoms/close-button';
import { AnswersHeader } from '../../atoms/answers-header';
import { UrgentAnswersHeader } from '../../atoms/urgent-answers-header';
import { AnswersAdvice } from '../../atoms/answers-advice';

import RestartIcon from '../../../assets/restart.svg';

import * as answerStyles from './index.module.css';

// Find any answers that have text and a score
const Answers = ({ answers }) => {
	// Get the total score of all answers
	const answersScore = getAnswersScore(answers);
	const hasNoComms = checkNoComms(answers);
	return (
		<>
			<section className="px-4 mt-7">
				<div className="mx-auto wrapper-small">
					<Header
						restartBtn={
							<CloseButton
								className="!py-xxs-f !px-l-f"
								destination="/"
								promptTitle="Restart Triage"
								promptDesc="Are you sure you want to restart? You will lose all information added so far."
								id="answersRestart"
							>
								<RestartIcon className="mr-2" />
								Restart
							</CloseButton>
						}
					/>
				</div>
			</section>
			{answersScore >= 1 ? (
				<>
					<section
						className={clsx(
							'p-4',
							'pb-12',
							'text-white',
							'bg-brand-blue-400',
							answerStyles.fullWidth
						)}
					>
						<div className="mx-auto wrapper-small">
							<UrgentAnswersHeader answers={answers} />
							{hasNoComms ? null : (
								<NextStepComponent answers={answers} urgent />
							)}
						</div>
					</section>
					<AnswersAdvice answers={answers} />
				</>
			) : (
				<>
					<section className="px-4">
						<div className="mx-auto wrapper-small">
							<AnswersHeader answers={answers} />
						</div>
					</section>
					<AnswersAdvice answers={answers} hasMargin />
					{hasNoComms ? null : (
						<section className="px-4 pb-10 bg-brand-pale-300">
							<div className="mx-auto wrapper-small">
								<NextStepComponent answers={answers} />
							</div>
						</section>
					)}
				</>
			)}
		</>
	);
};

Answers.propTypes = {
	answers: answersType.isRequired,
};

export { Answers };
