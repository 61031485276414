/**
 * Get the answers for the given question
 * @param {Array} answers - The answers to check
 * @param {String} questionId - The id of the question to get answers for
 */
export const getQuestionAnswers = (answers, questionId) =>
	answers.find((anAnswer) => String(anAnswer.id) === String(questionId));

/**
 * Get the answer index for the given question
 * @param {Array} answers - The answers to check
 * @param {String} questionId - The id of the question to get answers for
 */
export const findAnswerIndex = (answers, questionId) =>
	answers.findIndex((answer) => String(answer.id) === String(questionId));

/**
 * Used to loop through all of the questions on the current page
 * and count the number of questions
 * @param {Array} questions - The questions to loop through
 */
export const countQuestions = (questions) => {
	let intQuests = questions.length;
	questions.map((quest) => {
		if (quest.subQuestions && quest.subQuestions.length > 0) {
			intQuests += countQuestions(quest.subQuestions);
		}
		return true;
	});
	return intQuests;
};

/**
 * Used to loop through all of the questions on the current page
 * and count the number of questions that have been answered
 * @param {Array} questions - The questions to loop through
 * @param {Array} answers - The answers to check
 */
export const countAnswered = (questions, answers) => {
	let isAnswered = 0;
	questions.map((question) => {
		if (findAnswerIndex(answers, question.id) !== -1) {
			isAnswered++;
			if (question.subQuestions && question.subQuestions.length > 0) {
				isAnswered += countAnswered(question.subQuestions, answers);
			}
		}
		return true;
	});
	return isAnswered;
};

/**
 * Loops through the answers given up to now and removes everything after
 * the current question
 * @param {Array} answers - The answers to check
 * @param {Object} currentQuestion - The current question to remove answers for
 * @param {Function} setAnswers - The function to set the answers
 */
export const deleteNextAnswers = ({ answers, currentQuestion, setAnswers }) => {
	// Get the index of the current question
	const questionIndex = findAnswerIndex(answers, currentQuestion.id);
	if (questionIndex !== -1) {
		answers.map((_answer, key) => {
			if (key > questionIndex) {
				setAnswers((current) => {
					// Remove the question from the answers if there were none selected
					const copy = [...current];
					copy.splice(key, 1);
					return copy;
				});
			}
			return true;
		});
	}
};

/**
 * Checks all questions to see if they are invalid or not
 * @param {Array} questions - The questions to check
 * @param {Array} answers - The answers to check
 * @param {Function} setAnswers - The function to set the answers
 * @param {Function} setInvalid - The function to set the invalid questions
 * @todo - this might be easier as an array mapped to the answers
 */
export const checkInvalid = ({
	questions,
	answers,
	setIsInvalid,
	ignoreSub,
}) => {
	const indexes = answers.map((answer) => answer.id);
	questions.map((question) => {
		setIsInvalid((current) => ({
			...current,
			...{
				[String(question.id)]:
					indexes.indexOf(String(question.id)) === -1,
			},
		}));
		if (
			question.subQuestions &&
			question.subQuestions.length > 0 &&
			!ignoreSub
		) {
			checkInvalid({
				questions: question.subQuestions,
				answers,
				setIsInvalid,
				ignoreSub,
			});
		}
		return true;
	});
};

/**
 * Checks to see if answers' scores equal greater than 1 to trigger the urgent component
 * to show
 * @param {Array} answers - The answers to check
 * @returns {Integer} - THe overall score of the combined answers
 */
export const getAnswersScore = (answers) =>
	answers.reduce(
		(score, answer) =>
			answer.score && answer.resultText ? score + answer.score : score,
		0
	);

/**
 * Looks for an answer in the answers array and returns it and the index
 * @param {Array} answers - The answers to check
 * @param {String} questionId - The id of the question to find
 */
export const findAnswer = (answers, questionId) => [
	answers.length > 0 ? getQuestionAnswers(answers, questionId) : undefined,
	findAnswerIndex(answers, questionId),
];

/**
 * Loops through all of the answers and recursively looks for the parent
 * answer of the current one to remove all children where questions have
 * sub questions
 * @param {Array} answers - The answers to check
 * @param {String} questionId - The id of the question to find
 * @param {Array} allSubAnswers - Sub answers we've found so far
 */
export const findAllSubAnswers = (answers, questionId, allSubAnswers) => {
	const newSubAnswer = answers.find(
		(anAnswer) => String(anAnswer.parentQuestion) === String(questionId)
	);
	if (newSubAnswer?.parentQuestion) {
		const newAllSubAnswers = !allSubAnswers.includes(newSubAnswer)
			? [...allSubAnswers, newSubAnswer]
			: allSubAnswers;
		const nextSubAnswer = findAllSubAnswers(
			answers,
			newSubAnswer.id,
			newAllSubAnswers
		);
		return nextSubAnswer;
	}
	return allSubAnswers;
};

/**
 * Loops through all questions on the page and returns an array of
 * answers for each question
 * Found question is a question that already has been answered
 * @param {Array} questions - The questions to loop through
 * @param {Object} foundQuestion - The question to check for answers
 * @param {String} answer - The actual answer text
 */
export const getNewAnswers = (question, foundQuestion, answer) => {
	if (!question.isMultiple) {
		// Single choice questions only need to return an answer
		return [answer];
	}
	if (typeof foundQuestion !== 'undefined') {
		if (typeof foundQuestion.answers !== 'undefined') {
			if (foundQuestion.answers.includes(answer)) {
				return foundQuestion.answers.filter((id) => id !== answer);
			}
			return [...foundQuestion.answers, answer];
		}
		return [answer];
	}
	return [answer];
};

/**
 * Loops through the given question and any sub questions and deletes them all
 * @param {Object} question - The question to check for sub questions
 */
export const deleteSubQuestions = (question) => {
	const thisQuestion = question;
	if (thisQuestion.subQuestions && thisQuestion.subQuestions.length > 0) {
		thisQuestion.subQuestions.forEach((subQuestion) => {
			deleteSubQuestions(subQuestion);
		});
	}
	delete thisQuestion.subQuestions;
	return thisQuestion;
};

export const validate = (value, required, email) => {
	const emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$';
	const isValidEmail = (str) => new RegExp(emailPattern, 'i').test(str);
	let error = '';

	// early return if no validation needed
	if (!required && !value) {
		return error;
	}

	if (required) {
		if (!value) {
			error = 'Field is required';
			return error;
		}
	}
	if (email) {
		if (!isValidEmail(String(value || ''))) {
			error = 'Valid email address required';
		}
	}

	return error;
};

export const filterAnswersForSubmission = (answersArray) => {
	if (!answersArray) return [];
	return answersArray.map(({ id, question, answers }) => ({
		id,
		question,
		answers,
	}));
};

// Check if any questions have flagged as being 'noComms' and shouldn't show
// the Next Steps component
export const checkNoComms = (answers) => {
	const noCommsAns = answers.filter((answer) => answer.noComms);
	return noCommsAns.length > 0;
};

export const getDayOfCall = (day) => {
	// Starting at Sunday due to getDay() method starting with this
	const dayNames = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	const nextWorkingDay = [1, 2, 3, 4, 5, 1, 1];
	const now = new Date();
	const currDay = now.getDay();
	let dayName;

	if (day === 'Tomorrow') {
		if (nextWorkingDay[currDay] !== 1) {
			dayName = 'Tomorrow';
		} else {
			dayName = dayNames[nextWorkingDay[currDay]];
		}
	}
	if (day === 'DayAfterTomorrow') {
		if (nextWorkingDay[currDay] !== 1) {
			dayName = dayNames[nextWorkingDay[currDay + 1]];
		} else {
			dayName = dayNames[nextWorkingDay[currDay] + 1];
		}
	}

	return dayName;
};

export const getDateOfCall = (value) => {
	const dayName = getDayOfCall(value);

	// The current day
	const date = new Date();
	const now = date.getDay();

	// Days of the week
	const days = [
		'sunday',
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
	];

	// The index for the day you want
	const day = days.indexOf(dayName.toLowerCase());

	// Find the difference between the current day and the one you want
	// If it's the same day as today (or a negative number), jump to the next week
	const dayMinusNow = day - now;
	const diff = dayMinusNow < 1 ? 7 + dayMinusNow : dayMinusNow;

	// Get the timestamp for the desired day
	const nextDayTimestamp = date.getTime() + 1000 * 60 * 60 * 24 * diff;

	// Get the next day
	return new Date(nextDayTimestamp).toISOString().split('T')[0];
};

export const filterTimeRangeOptions = (options, value, direction) => {
	if (!value || value === '') return options;

	const valueIndex = options.findIndex((option) => option.value === value);

	if (valueIndex === -1) return options;

	return options.filter((_option, currentIndex) =>
		direction ? currentIndex > valueIndex : currentIndex < valueIndex
	);
};

// Returns a nicely formatted date string from the selected time value
export const getNiceHoursFromValue = (formEntries) => {
	const arrHours = formEntries.callTime.split('-');
	const strDay = getDayOfCall(
		formEntries.callWhen === 'callTomorrow'
			? 'Tomorrow'
			: 'DayAfterTomorrow'
	);
	return `${
		strDay === 'Tomorrow' ? 'tomorrow' : strDay
	} between the hours of ${arrHours[0]} and ${arrHours[1]}`;
};

export const getGAClientId = async () => {
	const gTagTrackingId = process.env.GATSBY_GA_TRACKING_ID;
	const hasGtag = typeof window.gtag === 'function';
	let id = '';

	if (!hasGtag) return '';

	// eslint-disable-next-line compat/compat
	const clientIdPromise = new Promise((resolve) => {
		if (!hasGtag) {
			id = '';
			resolve();
		}

		window.gtag('get', gTagTrackingId, 'client_id', resolve);
	});

	await clientIdPromise.then((clientId) => {
		id = clientId;
	});

	return id;
};
