import React from 'react';

import { answersType } from '../../../types';

export const UrgentAnswersHeader = ({ answers }) => {
	const filterAnswers = answers.find(
		(answer) => answer.type === 'subCategory'
	);
	const categories = filterAnswers ? filterAnswers.answers : [];
	return (
		<>
			<h1 className="my-[30px] text-lg-f font-centra-book">
				Due to some of your answers{' '}
				{categories.length > 0 ? 'about' : ''}{' '}
				{categories.length > 1 ? categories.join(' and ') : categories}{' '}
				we think it best if you spoke to an advisor.
			</h1>
		</>
	);
};

UrgentAnswersHeader.propTypes = {
	answers: answersType.isRequired,
};
